import React, { useState, useEffect } from 'react';
import Input from "../../components/util/Input";
import Button from "../../components/util/Button";
import { ResumeDataResponse } from '../../constants/types';
import apiClient from '../../service/instance';

interface CustomProps {
    submit: (tab?: number) => void;
}

interface Education {
    institution: string;
    degree: string;
    fieldOfStudy: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    description: string;
}

const Education = (props: CustomProps) => {
    const [saveBtnLoader, setSaveBtnLoader] = useState(false);
    const [educations, setEducations] = useState<Education[]>([{
        institution: '',
        degree: '',
        fieldOfStudy: '',
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
        description: ''
    }]);

    // useEffect(() => {
    //     const storedEducation = localStorage.getItem('education');
    //     if (storedEducation) {
    //         setEducations(JSON.parse(storedEducation));
    //     }
    // }, []);

    useEffect(() => {
        getResumeData();
    }, []);

    const getResumeData = async () => {
        try {
            const response: ResumeDataResponse = await apiClient.post('get_resume_data', {
                type: 'education'
            });
            if (response.data.status) {
                const savedData = JSON.parse(response.data.resume_data.cgmu_data);
                setEducations(savedData);
            }
        } catch (error) {
            console.error('Error fetching resume data:', error);
        }
    }



    const saveStep = async () => {
        setSaveBtnLoader(true);
        try {
            setSaveBtnLoader(false);
            // Filter out empty education entries
            const nonEmptyEducations = educations.filter(education =>
                education.institution || education.degree || education.fieldOfStudy ||
                education.startMonth || education.startYear || education.endMonth ||
                education.endYear || education.description
            );
            // Generate JSON data and save to local storage
            const educationData = JSON.stringify(nonEmptyEducations);
            localStorage.setItem('education', educationData);

            // Make API request
            const response = await apiClient.post('save_resume', {
                type: 'education',
                data: JSON.stringify(nonEmptyEducations)
            });

            if (!response.status) {
                throw new Error('Failed to save data');
            }
            props.submit(4);
            
        } catch (error) {
            console.error('Error saving data:', error);
        }
    }

    const handleEducationChange = (index: number, field: keyof Education, value: string) => {
        const newEducations = [...educations];
        newEducations[index][field] = value;
        setEducations(newEducations);
    };

    const addEducation = () => {
        setEducations([...educations, { institution: '', degree: '', fieldOfStudy: '', startMonth: '', startYear: '', endMonth: '', endYear: '', description: '' }]);
    };

    const removeEducation = (index: number) => {
        const newEducations = [...educations];
        newEducations.splice(index, 1);
        setEducations(newEducations);
    };

    const previousStep = () => {
        props.submit(2);
    }

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 50 }, (_, i) => currentYear - i);

    return (
        <>
            <div className='block mt-9 max-w-[90%] m-auto'>
                <form>
                    <div className="flex flex-col gap-4">
                        {educations.map((education, index) => (
                            <div key={index} className="flex flex-col gap-4 p-4 border border-gray-200 rounded-md relative bg-[#edeff3]">
                                <div className="flex flex-col">
                                    <label htmlFor={`institution-${index}`} className="block mb-2 text-sm text-gray-500">Institution</label>
                                    <Input
                                        onChange={(e) => handleEducationChange(index, 'institution', e.target.value)}
                                        placeholder="Enter institution name"
                                        customClass=""
                                        type="text"
                                        label=""
                                        error={''}
                                        value={education.institution}
                                        name={`institution-${index}`}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`degree-${index}`} className="block mb-2 text-sm text-gray-500">Degree</label>
                                    <Input
                                        onChange={(e) => handleEducationChange(index, 'degree', e.target.value)}
                                        placeholder="Enter degree"
                                        customClass=""
                                        type="text"
                                        label=""
                                        error={''}
                                        value={education.degree}
                                        name={`degree-${index}`}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`fieldOfStudy-${index}`} className="block mb-2 text-sm text-gray-500">Field of Study</label>
                                    <Input
                                        onChange={(e) => handleEducationChange(index, 'fieldOfStudy', e.target.value)}
                                        placeholder="Enter field of study"
                                        customClass=""
                                        type="text"
                                        label=""
                                        error={''}
                                        value={education.fieldOfStudy}
                                        name={`fieldOfStudy-${index}`}
                                    />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-col flex-1">
                                        <label htmlFor={`startMonth-${index}`} className="block mb-2 text-sm text-gray-500">Start Date</label>
                                        <div className="flex gap-2">
                                            <select
                                                onChange={(e) => handleEducationChange(index, 'startMonth', e.target.value)}
                                                value={education.startMonth}
                                                name={`startMonth-${index}`}
                                                className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs appearance-none w-[50%]"
                                            >
                                                <option value="">Month</option>
                                                {months.map((month) => (
                                                    <option key={month} value={month}>{month}</option>
                                                ))}
                                            </select>
                                            <select
                                                onChange={(e) => handleEducationChange(index, 'startYear', e.target.value)}
                                                value={education.startYear}
                                                name={`startYear-${index}`}
                                                className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs appearance-none w-[50%]"
                                            >
                                                <option value="">Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year.toString()}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-1">
                                        <label htmlFor={`endMonth-${index}`} className="block mb-2 text-sm text-gray-500">End Date</label>
                                        <div className="flex gap-2">
                                            <select
                                                onChange={(e) => handleEducationChange(index, 'endMonth', e.target.value)}
                                                value={education.endMonth}
                                                name={`endMonth-${index}`}
                                                className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs appearance-none w-[50%]"
                                            >
                                                <option value="">Month</option>
                                                {months.map((month) => (
                                                    <option key={month} value={month}>{month}</option>
                                                ))}
                                            </select>
                                            <select
                                                onChange={(e) => handleEducationChange(index, 'endYear', e.target.value)}
                                                value={education.endYear}
                                                name={`endYear-${index}`}
                                                className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs appearance-none w-[50%]"
                                            >
                                                <option value="">Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year.toString()}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`description-${index}`} className="block mb-2 text-sm text-gray-500">Description</label>
                                    <Input
                                        onChange={(e) => handleEducationChange(index, 'description', e.target.value)}
                                        placeholder="Describe your education"
                                        customClass=""
                                        type="textarea"
                                        label=""
                                        error={''}
                                        value={education.description}
                                        name={`description-${index}`}
                                    />
                                </div>
                                {index > 0 && (
                                    <button
                                        type="button"
                                        onClick={() => removeEducation(index)}
                                        className='absolute top-2 right-2 opacity-50'
                                    >
                                        <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" /></svg>
                                    </button>
                                )}
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addEducation}
                            className="text-blue-500 hover:text-blue-700 mt-2 flex flex-row items-center gap-2 justify-center font-normal text-sm"
                        >
                            Add Education
                            <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 176v160M336 256H176" /></svg>
                        </button>
                        <div className="flex justify-center pt-9 items-center gap-4">
                            <Button type="button" name={'Previous'} class="w-32" loader={saveBtnLoader} onClick={() => previousStep()} />
                            <Button type="button" name={'Next'} class="w-32" loader={saveBtnLoader} onClick={() => saveStep()} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Education;
