import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    return (
        <div className="flex justify-center items-center mb-11">
            <a onClick={() => navigate('/')}>
                <img src={`${process.env.PUBLIC_URL}/logo-bevel.png`} alt="logo" className="w-20" />
            </a>
        </div>
    )
}

export default Header;