// src/api/api.js
import axios from "axios";
import { getItem } from "./storageService";
import handleApiError from "./errorService";

const API_BASE_URL = process.env.REACT_APP_API_DOMAIN;
console.log("API_BASE_URL:", API_BASE_URL); // Debugging line

const apiClient = axios.create({
  baseURL: `${API_BASE_URL}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Optional: Add interceptors for request and response handling
apiClient.interceptors.request.use(
  (config) => {
    // Add token or any custom headers here
    const token = getItem("access_token")
    const lang = getItem("language");
    // config.headers.language = lang ? lang : "en";
    const email = getItem("email");
    if (token && email) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Email = email;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally here
    if (error.response && error.response.status === 401) {
      handleApiError(error);
    }
    return Promise.reject(error);
  }
);

export default apiClient;
