import React, { useRef, useEffect, useState } from 'react';
import { jsPDF } from "jspdf";
import Button from '../../../components/util/Button';

interface PersonalInfo {
    fullName: string;
    title: string;
    phoneNumbers: PhoneNumber[];
    email: string;
    website: string;
    profile: string;
    languages: Language[];
    currentPosition: string;
}

interface PhoneNumber {
    number: string;
    countryCode: string;
}

interface Skill {
    name: string;
}

interface Education {
    institution: string;
    degree: string;
    date: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    fieldOfStudy: string;
}

interface Project {
    name: string;
    role: string;
    date: string;
    description: string;
}

interface Experience {
    company: string;
    position: string;
    date: string;
    description: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    isCurrentCompany: boolean;
}

interface Language {
    language: string;
    level: string;
}

interface Props {
    downloadPdf?: boolean;
}

const FreeSans = (props: Props) => {
    const contentRef = useRef(null);
    const [personalInfo, setPersonalInfo] = useState<PersonalInfo | null>(null);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [education, setEducation] = useState<Education[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [experience, setExperience] = useState<Experience[]>([]);
    const [summary, setSummary] = useState<string>('');

    useEffect(() => {
        const personalInfoData = localStorage.getItem('personalinfo');
        const skillsData = localStorage.getItem('skills');
        const educationData = localStorage.getItem('education');
        const projectsData = localStorage.getItem('projects');
        const experienceData = localStorage.getItem('experience');
        const summaryData = localStorage.getItem('summary');

        if (personalInfoData) setPersonalInfo(JSON.parse(personalInfoData));
        if (skillsData) setSkills(JSON.parse(skillsData));
        if (educationData) setEducation(JSON.parse(educationData));
        if (projectsData) setProjects(JSON.parse(projectsData));
        if (experienceData) setExperience(JSON.parse(experienceData));
        if (summaryData) setSummary(JSON.parse(summaryData));

    }, []);

    useEffect(() => {
        // if (props.downloadPdf) {
        //     downloadPDF();
        // }
        downloadPDF();
    }, [props.downloadPdf]);

    const downloadPDF = () => {
        const doc = new jsPDF('p', 'px', 'a4');
        const content = contentRef.current;
        if (content) {
            // Optional - Add styling to the PDF
            doc.setFontSize(6);

            // Add HTML content to PDF
            doc.html(content, {
                callback: function (doc) {
                    doc.save(`${personalInfo?.fullName} resume.pdf`);
                },
                // x: 10,
                // y: 10,
                margin: 10,
                width: 2480,
                autoPaging: 'text',
                html2canvas: {
                    scale: 0.5, // Adjust scale to 1 for normal size (can be 2 for high quality)
                },
            });
        }
    }

    if (!personalInfo) {
        return <div>Loading CV data...</div>;
    }

    return (
        <>

            <div style={{ width: '884px', height: '100%', backgroundColor: 'white', border: '1px solid lightgray', margin: '20px auto', padding: '20px', }}>
                <div id="content-to-print" ref={contentRef} style={{
                    scale: 1,
                    width: '834px',
                    margin: '0 auto',
                    maxWidth: '100%'
                }}>
                    <table style={{
                        width: '844px',
                        fontFamily: 'Arial, sans-serif',
                        fontSize: '16px',
                        lineHeight: 1.6,
                        borderCollapse: 'collapse',
                        height: '1222px',
                    }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '40%', verticalAlign: 'top', paddingRight: '15px', borderRight: '1px solid lightgray' }}>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            {personalInfo && (
                                                <tr>
                                                    <td style={{ padding: '5px' }}>
                                                        {personalInfo.fullName && <h1 style={{ fontSize: '28px', fontWeight: 'bold', margin: 0, lineHeight: 1.2, marginBottom: '5px' }}>{personalInfo.fullName}</h1>}
                                                        {personalInfo.currentPosition && <p style={{ margin: 0 }}>{personalInfo.currentPosition}</p>}
                                                        {personalInfo.title && <p style={{ margin: 0 }}>{personalInfo.title}</p>}
                                                        <div style={{ margin: '10px 0 0px 0', fontSize: '14px' }}>
                                                            {personalInfo.phoneNumbers && personalInfo.phoneNumbers.length > 0 && personalInfo.phoneNumbers.map((phone, index) => (
                                                                <React.Fragment key={index}>
                                                                    {phone.countryCode && phone.number && (
                                                                        <p style={{ margin: 0 }}>
                                                                            {'+' + phone.countryCode} {phone.number}
                                                                            {index < personalInfo.phoneNumbers.length - 1 && <br />}
                                                                        </p>
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                        <div style={{ margin: '0px 0 10px 0', fontSize: '14px' }}>
                                                            {personalInfo.email && <p style={{ margin: 0 }}>{personalInfo.email}</p>}
                                                            {personalInfo.website && <p style={{ margin: 0 }}>{personalInfo.website}</p>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            {summary && (
                                                <tr>
                                                    <td style={{ padding: '5px', }}>
                                                        <h2 style={{ fontSize: '18px', margin: 0, fontWeight: 'bold' }}>PROFILE</h2>
                                                        <p style={{ margin: '10px 0', fontSize: '14px' }}>{summary}</p>
                                                    </td>
                                                </tr>
                                            )}
                                            {skills && skills.length > 0 && (
                                                <tr>
                                                    <td style={{ padding: '5px', }}>
                                                        <h2 style={{ fontSize: '18px', margin: 0, fontWeight: 'bold' }}>SKILLS</h2>
                                                        <table style={{ margin: '10px 0 10px 0px', padding: 0, fontSize: '14px', listStyleType: 'none' }}>
                                                            <tbody>
                                                                {skills.map((skill, index) => (
                                                                    <tr key={index} style={{ fontSize: '14px', margin: 0, }}>
                                                                        <td style={{ verticalAlign: 'center' }}>
                                                                            <span className='flex items-center justify-start'>
                                                                                <span className='text-gray-500 mr-2 text-[18px]'>&#x2022;</span>
                                                                                {skill.name}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}
                                            {/* Hide education section if projects array is empty */}
                                            {education && education.length > 0 && projects && projects.length > 0 && (
                                                <tr>
                                                    <td style={{ padding: '5px', }}>
                                                        <h2 style={{ fontSize: '18px', margin: 0, fontWeight: 'bold' }}>EDUCATION</h2>
                                                        {education.map((edu, index) => (
                                                            <div key={index} style={{ margin: '10px 0', fontSize: '14px' }}>
                                                                <p style={{ margin: '0 0 10px 0' }}>
                                                                    <strong>{edu.institution}</strong><br />
                                                                    {edu.degree}<br />
                                                                    {edu.fieldOfStudy}<br />
                                                                    {edu.startMonth} {edu.startYear} - {edu.endMonth} {edu.endYear}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            )}
                                            {personalInfo.languages && personalInfo.languages.length > 0 && (
                                                <tr>
                                                    <td style={{ padding: '5px', }}>
                                                        <h2 style={{ fontSize: '18px', margin: 0, fontWeight: 'bold' }}>LANGUAGES</h2>
                                                        <ul style={{ margin: '10px 0 0px 0px', padding: 0, fontSize: '14px', listStyleType: 'none' }}>
                                                            {personalInfo.languages.map((lang, index) => (
                                                                <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span style={{ color: 'gray', marginRight: '8px', fontSize: '18px' }}>&#x2022;</span>
                                                                    {lang.language} - {lang.level}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </td>
                                <td style={{ width: '60%', verticalAlign: 'top', paddingLeft: '15px' }}>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            {experience && experience.length > 0 && (
                                                <tr>
                                                    <td style={{ padding: '5px' }}>
                                                        <h2 style={{ fontSize: '18px', margin: 0, fontWeight: 'bold' }}>WORK EXPERIENCE</h2>
                                                        {experience.map((exp, index) => (
                                                            <div key={index} style={{ margin: '10px 0 15px 0', fontSize: '14px' }}>
                                                                <p style={{ margin: '0 0 10px 0' }}>
                                                                    <strong>{exp.company}</strong><br />
                                                                    <div style={{ fontStyle: 'italic' }}>
                                                                        {exp.startMonth} {exp.startYear} 
                                                                        {!exp.isCurrentCompany && ` - ${exp.endMonth} ${exp.endYear}`}
                                                                        {exp.isCurrentCompany && ' (Current)'}
                                                                    </div>
                                                                    {exp.position}<br />

                                                                </p>
                                                                <p>{exp.description}</p>
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            )}
                                            {projects && projects.length > 0 ? (
                                                <tr>
                                                    <td style={{ padding: '5px' }}>
                                                        <h2 style={{ fontSize: '18px', margin: 0, fontWeight: 'bold' }}>PROJECTS</h2>
                                                        {projects.map((project, index) => (
                                                            <div key={index} style={{ margin: '10px 0 15px 0', fontSize: '14px' }}>
                                                                <p style={{ margin: '0 0 5px 0' }}><strong>{project.name}</strong><br />{project.role}</p>
                                                                <p>{project.description}</p>
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            ) : (
                                                // Show education details if projects array is empty
                                                education && education.length > 0 && (
                                                    <tr>
                                                        <td style={{ padding: '5px', }}>
                                                            <h2 style={{ fontSize: '18px', margin: 0, fontWeight: 'bold' }}>EDUCATION</h2>
                                                            {education.map((edu, index) => (
                                                                <div key={index} style={{ margin: '10px 0', fontSize: '14px' }}>
                                                                    <p style={{ margin: '0 0 10px 0' }}>
                                                                        <strong>{edu.institution}</strong><br />
                                                                        {edu.degree}<br />
                                                                        {edu.fieldOfStudy}<br />
                                                                        {edu.startMonth} {edu.startYear} - {edu.endMonth} {edu.endYear}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className='flex justify-center mb-10 mt-10 max-w-full'>
                <Button type="button" name={'Print Preview'} class="w-40" onClick={() => downloadPDF()} />
            </div> */}
        </>
    );
};

export default FreeSans;
