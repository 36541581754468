import React from 'react';
import { tabs } from '../constants/resumeTabs';

interface ProgressBarProps {
    activeTab: number;
    changeTab: (tab: number) => void;
}

const ProgressBar = ({ activeTab, changeTab }: ProgressBarProps) => {

    

    return (
        <div className="mx-auto w-11/12">
            <div className="flex h-1 items-center justify-between bg-gray-200">
                <div className="relative flex h-[2px] w-[99%] items-center justify-between bg-indigo-700">
                    {tabs.map((tab, index) => (
                        <div key={tab.id} className={`flex cursor-pointer h-6 w-6 items-center justify-center rounded-full ${index < activeTab ? 'bg-indigo-700' : index === activeTab ? 'bg-white' : 'bg-white border-indigo-700/20 border-2'} shadow`} onClick={() => changeTab(index)}>
                            {index < activeTab && (
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <path d="M5 12l5 5l10 -10" />
                                </svg>
                            )}
                            {index === activeTab && (
                                <>
                                    <div className="h-3 w-3 rounded-full bg-indigo-700 animate-pulse"></div>
                                    <div className="absolute top-[-15px] text-center max-w-[100px] w-full left-auto right-auto">
                                        <div className="relative -mt-6 rounded bg-white px-2 py-1 shadow-lg">
                                            <p className="text-xs font-bold text-indigo-700 focus:outline-none">{tab.name}</p>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;