import React, { useState, FormEvent, useEffect } from 'react';
import Input from "../../components/util/Input";
import Button from "../../components/util/Button";
import { PersonalInfoData, ResumeDataResponse } from '../../constants/types';
import { getItem } from '../../service/storageService';
import apiClient from '../../service/instance';

interface CustomProps {
    submit: (tab?: number) => void;
}

const PersonalInfo = (props: CustomProps) => {
    const [saveBtnLoader, setSaveBtnLoader] = useState(false);
    const [formData, setFormData] = useState<PersonalInfoData>({
        fullName: '',
        email: '',
        phoneNumbers: [{ countryCode: '', number: '' }],
        country: '',
        address: '',
        zipCode: '',
        website: '',
        currentPosition: '',
        languages: [{ language: '', level: 'Beginner' }]
    });
    const [phoneError, setPhoneError] = useState('');

    // useEffect(() => {
    //     const storedData = localStorage.getItem('personalinfo');
    //     if (storedData) {
    //         setFormData(JSON.parse(storedData));
    //     }
    // }, []);
    useEffect(() => {
        getResumeData();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handlePhoneChange = (index: number, field: 'countryCode' | 'number', value: string) => {
        const newPhoneNumbers = [...formData.phoneNumbers];
        newPhoneNumbers[index][field] = value;
        setFormData(prevData => ({
            ...prevData,
            phoneNumbers: newPhoneNumbers
        }));
    };

    const addPhoneNumber = () => {
        setFormData(prevData => ({
            ...prevData,
            phoneNumbers: [...prevData.phoneNumbers, { countryCode: '', number: '' }]
        }));
    };

    const removePhoneNumber = (index: number) => {
        const newPhoneNumbers = [...formData.phoneNumbers];
        newPhoneNumbers.splice(index, 1);
        setFormData(prevData => ({
            ...prevData,
            phoneNumbers: newPhoneNumbers
        }));
    };

    const handleLanguageChange = (index: number, field: 'language' | 'level', value: string) => {
        const newLanguages = [...formData.languages];
        newLanguages[index][field] = value;
        setFormData(prevData => ({
            ...prevData,
            languages: newLanguages
        }));
    };

    const addLanguage = () => {
        setFormData(prevData => ({
            ...prevData,
            languages: [...prevData.languages, { language: '', level: 'Beginner' }]
        }));
    };

    const removeLanguage = (index: number) => {
        const newLanguages = [...formData.languages];
        newLanguages.splice(index, 1);
        setFormData(prevData => ({
            ...prevData,
            languages: newLanguages
        }));
    };

    const getResumeData = async () => {
        try {
            const response: ResumeDataResponse = await apiClient.post('get_resume_data', {
                type: 'personalinfo'
            });

            if (response.data.status) {
                const savedData = JSON.parse(response.data.resume_data.cgmu_data);
                setFormData(savedData);
            }
        } catch (error) {
            console.error('Error fetching resume data:', error);
            // Handle error here (e.g., show error message to user)
        }
    };

    

    const saveStep = async (e: FormEvent) => {
        e.preventDefault();
        setSaveBtnLoader(true);
        
        try {
            const filteredLanguages = formData.languages.filter(lang => lang.language.trim() !== '');
            const dataToSave = { ...formData, languages: filteredLanguages };
            // Save to localStorage
            localStorage.setItem('personalinfo', JSON.stringify(dataToSave));
            
            // Make API request
            const response = await apiClient.post('save_resume', {
                type: 'personalinfo',
                data: JSON.stringify(dataToSave)
            });

            if (!response.status) {
                throw new Error('Failed to save data');
            }
            props.submit(1);
            
        } catch (error) {
            console.error('Error saving data:', error);
            // Handle error here (e.g., show error message to user)
        } finally {
            setSaveBtnLoader(false);
        }
    }

    return (
        <>
            <div className='block mt-9 max-w-[90%] m-auto'>
                <form onSubmit={saveStep}>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col">
                            <label htmlFor="fullName" className="block mb-2 text-sm text-gray-500">Full Name</label>
                            <Input
                                onChange={handleInputChange}
                                placeholder="Enter your full name"
                                customClass=""
                                type="text"
                                label=""
                                error={''}
                                value={formData.fullName}
                                name="fullName"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="currentPosition" className="block mb-2 text-sm text-gray-500">Current Position</label>
                            <Input
                                onChange={handleInputChange}
                                placeholder="Enter your current position"
                                customClass=""
                                type="text"
                                label=""
                                error={''}
                                value={formData.currentPosition}
                                name="currentPosition"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="email" className="block mb-2 text-sm text-gray-500">Email Address</label>
                            <Input
                                onChange={handleInputChange}
                                placeholder="Enter your email address"
                                customClass=""
                                type="email"
                                label=""
                                error={''}
                                value={formData.email}
                                name="email"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="phone" className="block mb-2 text-sm text-gray-500">Phone Number(s)</label>
                            {formData.phoneNumbers.map((phone, index) => (
                                <div key={index} className='flex flex-row items-center justify-between gap-2 mb-3 relative'>
                                    <Input
                                        onChange={(e) => handlePhoneChange(index, 'countryCode', e.target.value)}
                                        placeholder="Code"
                                        customClass="w-full"
                                        type="text"
                                        label=""
                                        error={''}
                                        value={phone.countryCode}
                                        name={`countrycode-${index}`}
                                        parentClass="w-28"
                                    />
                                    <Input
                                        onChange={(e) => handlePhoneChange(index, 'number', e.target.value)}
                                        placeholder="Phone number"
                                        customClass="w-full"
                                        type="tel"
                                        label=""
                                        error={''}
                                        value={phone.number}
                                        name={`phone-${index}`}
                                        parentClass="w-full"
                                    />
                                    {index > 0 && (
                                        <button
                                            type="button"
                                            onClick={() => removePhoneNumber(index)}
                                            className='absolute right-2 opacity-50'
                                        >
                                            <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" /></svg>
                                        </button>
                                    )}
                                </div>
                            ))}
                            {formData.phoneNumbers.length < 3 && (
                                <button
                                    type="button"
                                    onClick={addPhoneNumber}
                                    className="text-blue-500 hover:text-blue-700 mt-2 flex flex-row items-center gap-2 justify-center font-normal text-sm"
                                >
                                    Add Phone Number
                                    <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 176v160M336 256H176" /></svg>
                                </button>
                            )}

                            {phoneError && <p className="text-red-500 text-sm mt-1">{phoneError}</p>}
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="country" className="block mb-2 text-sm text-gray-500">Country</label>
                            <Input
                                onChange={handleInputChange}
                                placeholder="Enter your country"
                                customClass=""
                                type="text"
                                label=""
                                error={''}
                                value={formData.country}
                                name="country"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="address" className="block mb-2 text-sm text-gray-500">Address</label>
                            <Input
                                onChange={handleInputChange}
                                placeholder="Enter your address"
                                customClass=""
                                type="textarea"
                                label=""
                                error={''}
                                value={formData.address}
                                name="address"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="zipCode" className="block mb-2 text-sm text-gray-500">Zip Code</label>
                            <Input
                                onChange={handleInputChange}
                                placeholder="Enter your zip code"
                                customClass=""
                                type="text"
                                label=""
                                error={''}
                                value={formData.zipCode}
                                name="zipCode"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="website" className="block mb-2 text-sm text-gray-500">Website</label>
                            <Input
                                onChange={handleInputChange}
                                placeholder="Enter your website URL"
                                customClass=""
                                type="text"
                                label=""
                                error={''}
                                value={formData.website}
                                name="website"
                            />
                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="languages" className="block mb-2 text-sm text-gray-500">Languages</label>
                            {formData.languages?.map((lang, index) => (
                                <div key={index} className='flex flex-row items-center justify-between gap-2 mb-3 relative'>
                                    <Input
                                        onChange={(e) => handleLanguageChange(index, 'language', e.target.value)}
                                        placeholder="Language"
                                        customClass="w-full"
                                        type="text"
                                        label=""
                                        error={''}
                                        value={lang.language}
                                        name={`language-${index}`}
                                    />
                                    <select
                                        onChange={(e) => handleLanguageChange(index, 'level', e.target.value)}
                                        value={lang.level}
                                        className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs appearance-none w-36"
                                    >
                                        <option value="Beginner">Beginner</option>
                                        <option value="Intermediate">Intermediate</option>
                                        <option value="Advanced">Advanced</option>
                                        <option value="Fluent">Fluent</option>
                                        <option value="Native">Native</option>
                                    </select>
                                    {index > 0 && (
                                        <button
                                            type="button"
                                            onClick={() => removeLanguage(index)}
                                            className='absolute right-2 opacity-50'
                                        >
                                            <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" /></svg>
                                        </button>
                                    )}
                                </div>
                            ))}
                            {formData.languages?.length < 5 && (
                                <button
                                    type="button"
                                    onClick={addLanguage}
                                    className="text-blue-500 hover:text-blue-700 mt-2 flex flex-row items-center gap-2 justify-center font-normal text-sm"
                                >
                                    Add Language
                                    <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 176v160M336 256H176" /></svg>
                                </button>
                            )}
                        </div>
                        <div className="flex justify-center pt-9">
                            <Button type="submit" name={'Save'} class="w-32" loader={saveBtnLoader} onClick={() => { }} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default PersonalInfo;
