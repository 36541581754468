import React, { useRef, useEffect, useState } from 'react';
import Header from './Header';
import FreeSans from './designs/FreeSans';
import Button from '../../components/util/Button';
import { useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    Razorpay: any;
  }
}

const ViewCv = () => {
    const [scale, setScale] = useState(1);
    const containerRef = useRef<HTMLDivElement>(null);
    const [downloadPdf, setDownloadPdf] = useState(false);
    const [containerHeight, setContainerHeight] = useState(0);
    const [showPayment, setShowPayment] = useState(false);
    const [purchaseCompleted, setPurchaseCompleted] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            let newScale = 1;
            if (screenWidth <= 1000) {
                newScale = screenWidth / 1000;
            }
            setScale(newScale);

            // Update container height after scaling
            // if (containerRef.current) {
            //     const scaledHeight = containerRef.current.scrollHeight;
            //     setContainerHeight(scaledHeight);
            // }
        };

        // Initial call
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigate = useNavigate();

    const downloadPDF = () => {
        setDownloadPdf(!downloadPdf);
        console.log('downloading pdf');
    }

    useEffect(() => {
        const loadRazorpay = () => {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = 'https://checkout.razorpay.com/v1/checkout.js';
                script.onload = () => {
                    resolve(true);
                };
                script.onerror = () => {
                    resolve(false);
                };
                document.body.appendChild(script);
            });
        };

        loadRazorpay();
    }, []);

    const handleDownloadClick = async () => {
        if (purchaseCompleted) {
            downloadPDF();
        } else {
            setShowPayment(true);
            if (typeof window.Razorpay === 'undefined') {
                alert('Razorpay SDK failed to load. Please check your internet connection.');
                return;
            }

            const options = {
                key: 'rzp_live_H4owynDQx2W8Xf',
                amount: 100, // Amount in paise (e.g., 100000 for ₹1000)
                currency: 'INR',
                name: 'WioCV',
                description: 'Resume Download',
                handler: function(response: any) {
                    // Handle successful payment
                    setPurchaseCompleted(true);
                    downloadPDF();
                },
                prefill: {
                    name: 'User Name',
                    email: 'user@example.com',
                    contact: '9999999999'
                },
                theme: {
                    color: '#3399cc'
                }
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        }
    };

    return (
        <>
            <div className='mt-8'>
                <Header />
            </div>
            <div className='resumeBoxParent'>
                <div
                    className='resumeBoxContainer'
                    ref={containerRef}
                    style={{
                        transform: `scale(${scale})`,
                        transformOrigin: 'top left',
                        width: `${100 / scale}%`,
                        height: `${100 / scale}%`,
                    }}
                >
                    <FreeSans downloadPdf={downloadPdf} />
                </div>
            </div>
            <div className='flex justify-center mb-10 mt-10 max-w-full max-lg:fixed bottom-0 right-0 left-0 gap-2'>
                <Button type="button" name={'Back'} class="w-40" onClick={() => navigate('/')} />
                <Button 
                    type="button" 
                    name={purchaseCompleted ? 'Download PDF' : 'Purchase and Download'} 
                    class="w-40" 
                    onClick={handleDownloadClick} 
                />
            </div>
        </>
    );
};

export default ViewCv;
