import React, { useState, useEffect } from 'react';
import Input from "../../components/util/Input";
import Button from "../../components/util/Button";
import apiClient from '../../service/instance';
import { ResumeDataResponse } from '../../constants/types';

interface CustomProps {
    submit: (tab?: number) => void;
}

interface Skill {
    name: string;
    level: string;
    years: string;
    months: string;
}

const Skills = (props: CustomProps) => {
    const [saveBtnLoader, setSaveBtnLoader] = useState(false);
    const [skills, setSkills] = useState<Skill[]>([{
        name: '',
        level: '',
        years: '',
        months: ''
    }]);

    // useEffect(() => {
    //     const storedSkills = localStorage.getItem('skills');
    //     if (storedSkills) {
    //         setSkills(JSON.parse(storedSkills));
    //     }
    // }, []);

    useEffect(() => {
        getResumeData();
    }, []);

    const getResumeData = async () => {
        try {
            const response: ResumeDataResponse = await apiClient.post('get_resume_data', {
                type: 'skills'
            });
            if (response.data.status) {
                const savedData = JSON.parse(response.data.resume_data.cgmu_data);
                setSkills(savedData);
            }
        } catch (error) {
            console.error('Error fetching resume data:', error);
        }
    }

    const saveStep = async () => {
        setSaveBtnLoader(true);
        try {
            setSaveBtnLoader(false);
            // Filter out skills that are empty
            const filteredSkills = skills.filter(skill => skill.name || skill.level || skill.years || skill.months);
            // Generate JSON data and save to local storage
            const skillsData = JSON.stringify(filteredSkills);

            localStorage.setItem('skills', skillsData);

            // Make API request
            const response = await apiClient.post('save_resume', {
                type: 'skills',
                data: JSON.stringify(filteredSkills)
            });

            if (!response.status) {
                throw new Error('Failed to save data');
            }
            props.submit(3);

        } catch (error) {
            console.error('Error saving data:', error);
        }
    }

    const handleSkillChange = (index: number, field: keyof Skill, value: string) => {
        const newSkills = [...skills];
        newSkills[index][field] = value;
        setSkills(newSkills);
    };

    const addSkill = () => {
        setSkills([...skills, { name: '', level: '', years: '', months: '' }]);
    };

    const removeSkill = (index: number) => {
        const newSkills = [...skills];
        newSkills.splice(index, 1);
        setSkills(newSkills);
    };

    const previousStep = () => {
        props.submit(1);
    }

    return (
        <>
            <div className='block mt-9 max-w-[90%] m-auto'>
                <form>
                    <div className="flex flex-col gap-4">
                        {skills.map((skill, index) => (
                            <div key={index} className="flex flex-col gap-4 p-4 border border-gray-200 rounded-md relative bg-[#edeff3]">
                                <div className="flex flex-col">
                                    <label htmlFor={`skill-${index}`} className="block mb-2 text-sm text-gray-500">Skill</label>
                                    <Input
                                        onChange={(e) => handleSkillChange(index, 'name', e.target.value)}
                                        placeholder="Enter skill name"
                                        customClass=""
                                        type="text"
                                        label=""
                                        error={''}
                                        value={skill.name}
                                        name={`skill-${index}`}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`level-${index}`} className="block mb-2 text-sm text-gray-500">Level (0-100)</label>
                                    <div className="flex items-center">
                                        <input
                                            type='range'
                                            className='slider-color flex-grow'
                                            min="0"
                                            max="100"
                                            value={skill.level}
                                            onChange={(e) => handleSkillChange(index, 'level', e.target.value)}
                                        />
                                        <span className="ml-2 text-sm text-gray-500">{skill.level}%</span>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-col flex-1">
                                        <label htmlFor={`years-${index}`} className="block mb-2 text-sm text-gray-500">Years of Experience</label>
                                        <div className='flex flex-row gap-4'>
                                            <Input
                                                onChange={(e) => handleSkillChange(index, 'years', e.target.value)}
                                                placeholder="Years"
                                                customClass=""
                                                type="number"
                                                label=""
                                                error={''}
                                                value={skill.years}
                                                name={`years-${index}`}
                                            />
                                            <Input
                                                onChange={(e) => handleSkillChange(index, 'months', e.target.value)}
                                                placeholder="Months"
                                                customClass=""
                                                type="number"
                                                label=""
                                                error={''}
                                                value={skill.months}
                                                name={`months-${index}`}
                                            />
                                        </div>
                                    </div>

                                </div>
                                {index > 0 && (
                                    <button
                                        type="button"
                                        onClick={() => removeSkill(index)}
                                        className='absolute top-2 right-2 opacity-50'
                                    >
                                        <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" /></svg>
                                    </button>
                                )}
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addSkill}
                            className="text-blue-500 hover:text-blue-700 mt-2 flex flex-row items-center gap-2 justify-center font-normal text-sm"
                        >
                            Add Skill
                            <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 176v160M336 256H176" /></svg>
                        </button>
                        <div className="flex justify-center pt-9 items-center gap-4">
                            <Button type="button" name={'Previous'} class="w-32" loader={saveBtnLoader} onClick={() => previousStep()} />
                            <Button type="button" name={'Next'} class="w-32" loader={saveBtnLoader} onClick={() => saveStep()} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Skills;
