import { useEffect, useRef, useState } from "react";
import Button from "../../components/util/Button";
import Input from "../../components/util/Input";
import { useNavigate } from "react-router-dom";
import { postRequestOtp, postVerifyOtp } from "../../service/user";

interface AuthResponse {
    status: boolean;
    otp_hash: string;
    state: string;
    message: string;
}

const AuthScreen = () => {
    const [authBtnLoader, setAuthBtnLoader] = useState(false);
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [emailError, setEmailError] = useState("");
    const [otpError, setOtpError] = useState("");
    const otpInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [authResponse, setAuthResponse] = useState<AuthResponse | null>(null);

    const validateEmail = (email: string) => {
        // Simple email validation regex
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const authLogin = async (event: React.FormEvent) => {
        event.preventDefault(); // Prevent the form from submitting the traditional way
        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            return;
        }
        setAuthBtnLoader(true);
        setEmailError("");

        try {
            const response = await postRequestOtp(email);
            if (response.status) {
                setAuthResponse(response); // Save the response in the state
                setIsOtpSent(true);
            } else {
                setEmailError(response.message || "Failed to send OTP.");
            }
        } catch (error) {
            setEmailError("An error occurred. Please try again.");
        } finally {
            setAuthBtnLoader(false);
        }
    };

    const submitOtp = async (event: React.FormEvent) => {
        event.preventDefault(); // Prevent the form from submitting the traditional way
        if (otp.length !== 4) {
            setOtpError("Please enter a valid 4-digit OTP.");
            return;
        }
        setAuthBtnLoader(true);
        setOtpError("");

        if (!authResponse) {
            setOtpError("No authentication response found.");
            setAuthBtnLoader(false);
            return;
        }

        try {
            const response = await postVerifyOtp(otp, authResponse.otp_hash);
            if (response.status) {
                setSuccessMessage("Email and OTP verified successfully!");
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('email', email);
                localStorage.setItem('access_token', response.access_token);
                navigate('/create-cv');
                console.log("response", response);
            } else {
                setOtpError(response.message || "Failed to verify OTP.");
            }
        } catch (error) {
            setOtpError("An error occurred. Please try again.");
        } finally {
            setAuthBtnLoader(false);
        }
    };

    const editEmail = () => {
        setIsOtpSent(false);
    }

    useEffect(() => {
        if (isOtpSent && otpInputRef.current) {
            otpInputRef.current.focus();
        }
    }, [isOtpSent]);

    return (
        <>

            <div className="flex items-center justify-center h-screen">
                <form
                    className="max-w-96 w-full bg-clip-border rounded-md p-10 grid gap-4 mb-6 grid-cols-1"
                    onSubmit={!isOtpSent ? authLogin : submitOtp} // Handle form submission for both states
                >
                    <div className="flex items-center justify-center">
                        <img src={`${process.env.PUBLIC_URL}/logo-bevel.png`} alt="logo" className="w-20" />
                        {/* <span className="text-2xl font-bold ml-2">WioCV</span> */}
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="email" className="block mb-2 text-sm text-gray-500">Email Address</label>
                        <Input
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email to get Security Code"
                            customClass=""
                            type="email"
                            label=""
                            error={emailError}
                            defaultValue=""
                            disabled={isOtpSent}
                        />
                    </div>

                    {isOtpSent && (
                        <>
                            <div className="flex flex-col">
                                <label htmlFor="otp" className="block mb-2 text-sm text-gray-500">Security Code (OTP)</label>
                                <Input
                                    onChange={(e) => setOtp(e.target.value)}
                                    placeholder="One time password"
                                    customClass=""
                                    type="number"
                                    label=""
                                    error={otpError}
                                    defaultValue=""
                                    disabled={false}
                                    ref={otpInputRef}
                                />
                            </div>

                            <div className="block text-center">
                                <span className="block mb-2 text-xs text-gray-500">An OTP has been sent to your email. <strong className="cursor-pointer" onClick={editEmail}>Edit/Resend</strong> </span>
                            </div>
                        </>
                    )}



                    <div className="text-center mt-5 mb-3">
                        {!isOtpSent ? (
                            <Button type="submit" name={'Send Code'} class="w-32" loader={authBtnLoader} onClick={() => authLogin} />
                        ) : (
                            <Button type="submit" name={'Verify OTP'} class="w-32" loader={authBtnLoader} onClick={() => submitOtp} />
                        )}
                    </div>

                    <div className="companyInfo text-center text-[9px]">
                        <span>Powered by </span>
                        <a href="https://codeburylabs.com" target="_blank" className=" text-green-800" rel="noopener noreferrer">Codeburylabs Pvt. Ltd.</a>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AuthScreen;
