import React, { useState, useEffect } from 'react';
import Input from "../../components/util/Input";
import Button from "../../components/util/Button";
import { useNavigate } from 'react-router-dom';
import apiClient from '../../service/instance';
import { ResumeDataResponse } from '../../constants/types';
interface CustomProps {
    submit: (tab?: number) => void;
}

const Summary = (props: CustomProps) => {
    const [saveBtnLoader, setSaveBtnLoader] = useState(false);
    const [summary, setSummary] = useState('');
    const navigate = useNavigate();

    // useEffect(() => {
    //     const storedSummary = localStorage.getItem('summary');
    //     if (storedSummary) {
    //         setSummary(JSON.parse(storedSummary));
    //     }
    // }, []);

    useEffect(() => {
        getResumeData();
    }, []);

    const getResumeData = async () => {
        try {
            const response: ResumeDataResponse = await apiClient.post('get_resume_data', {
                type: 'summary'
            });
            if (response.data.status) {
                const savedData = JSON.parse(response.data.resume_data.cgmu_data);
                setSummary(savedData);
            }
        } catch (error) {
            console.error('Error fetching resume data:', error);
        }
    }


    const saveStep = async () => {
        setSaveBtnLoader(true);
        try {
            setSaveBtnLoader(false);
            // Generate JSON data and save to local storage
            const summaryData = JSON.stringify(summary);
            localStorage.setItem('summary', summaryData);

            // Make API request
            const response = await apiClient.post('save_resume', {
                type: 'summary',
                data: JSON.stringify(summary)
            });

            if (!response.status) {
                throw new Error('Failed to save data');
            }
            navigate('/view-cv');
        } catch (error) {
            console.error('Error saving data:', error);
        }
    }

    const handleSummaryChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSummary(e.target.value);
    };

    const previousStep = () => {
        props.submit(4);
    }

    return (
        <>
            <div className='block mt-9 max-w-[90%] m-auto'>
                <form>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col">
                            <label htmlFor="summary" className="block mb-2 text-sm text-gray-500">Professional Summary</label>
                            <Input
                                onChange={handleSummaryChange}
                                placeholder="Write a brief summary of your professional experience and skills..."
                                customClass="h-80"
                                type="textarea"
                                label=""
                                error={''}
                                value={summary}
                                name="summary"
                            />
                        </div>
                        <div className="flex justify-center pt-9 items-center gap-4">
                            <Button type="button" name={'Previous'} class="w-32" loader={saveBtnLoader} onClick={() => previousStep()} />
                            <Button type="button" name={'Finish'} class="w-32" loader={saveBtnLoader} onClick={() => saveStep()} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Summary;
